import { useState, useCallback } from 'react';
import axios from 'axios';
import { ChatMessage, ChatResponse } from '../types';
import { extractChatResponse } from '../utils/chatUtils';
import { config } from '../config';

export const useChat = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const addMessage = useCallback((message: Omit<ChatMessage, 'timestamp'>) => {
    setMessages(prev => [...prev, { ...message, timestamp: Date.now() }]);
  }, []);

  const sendMessage = useCallback(async (content: string) => {
    setIsLoading(true);
    try {
      addMessage({ role: 'user', content });

      const response = await axios.post<ChatResponse>(`${config.apiUrl}/chat`, {
        message: content
      });

      const cleanedResponse = extractChatResponse(response.data);
      
      if (cleanedResponse) {
        addMessage({ role: 'assistant', content: cleanedResponse });
      }

      return response.data;
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [addMessage]);

  return {
    messages,
    isLoading,
    sendMessage
  };
};
