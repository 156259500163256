import { ChatResponse } from '../types';

export const extractChatResponse = (response: ChatResponse): string => {
  // Get the response text, prioritizing the 'response' field
  const responseText = response.response || response.text || '';
  
  return cleanResponseText(responseText);
};

export const cleanResponseText = (text: string): string => {
  return text
    // Remove any "undefined:" prefix
    .replace(/^undefined:\s*/i, '')
    // Remove any asterisks used for formatting
    .replace(/[*]/g, '')
    // Remove any leading/trailing colons and whitespace
    .replace(/^[:\s]+|[:\s]+$/g, '')
    // Remove any "Assistant:" or similar prefixes
    .replace(/^(assistant|bot|ai):\s*/i, '')
    // Clean up multiple spaces
    .replace(/\s+/g, ' ')
    .trim();
};

export const formatMessage = (message: string): string => {
  return cleanResponseText(message);
};
