import React from 'react';
import { MessageCircle, User } from 'lucide-react';

interface ChatMessageProps {
  role: 'user' | 'assistant';
  content: string;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({ role, content }) => {
  const isUser = role === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
      <div className={`flex items-start max-w-[80%] ${isUser ? 'flex-row-reverse' : 'flex-row'}`}>
        <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
          isUser ? 'bg-blue-600 ml-2' : 'bg-purple-600 mr-2'
        }`}>
          {isUser ? <User size={16} /> : <MessageCircle size={16} />}
        </div>
        <div className={`p-3 rounded-lg ${
          isUser ? 'bg-blue-600' : 'bg-purple-600'
        }`}>
          <p className="text-gray-100 text-sm whitespace-pre-wrap break-words">{content}</p>
        </div>
      </div>
    </div>
  );
};
