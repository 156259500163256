import React, { useState } from 'react';
import { ChatInterface } from './components/ChatInterface';
import { DebugConsole } from './components/DebugConsole';
import { useChat } from './hooks/useChat';
import { DebugLog } from './types';

function App() {
  const { messages, isLoading, sendMessage } = useChat();
  const [inputValue, setInputValue] = useState('');
  const [debugLogs, setDebugLogs] = useState<DebugLog[]>([]);

  const addDebugLog = (log: Omit<DebugLog, 'timestamp'>) => {
    setDebugLogs(prev => [...prev, { ...log, timestamp: Date.now() }]);
  };

  const handleSendMessage = async (message: string) => {
    try {
      addDebugLog({
        type: 'info',
        message: 'Sending message to API',
        details: { message },
      });

      const response = await sendMessage(message);

      addDebugLog({
        type: 'success',
        message: 'Received API response',
        details: response,
      });
    } catch (error) {
      addDebugLog({
        type: 'error',
        message: 'Error sending message',
        details: error,
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-8">
      <div className="max-w-4xl mx-auto space-y-6">
        <h1 className="text-2xl font-bold mb-6">AI Chat Interface</h1>
        <ChatInterface
          messages={messages}
          onSendMessage={handleSendMessage}
          inputValue={inputValue}
          setInputValue={setInputValue}
          isLoading={isLoading}
        />
        <DebugConsole logs={debugLogs} />
      </div>
    </div>
  );
}

export default App;