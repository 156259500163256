import React from 'react';
import { DebugLog } from '../types';
import { Terminal } from 'lucide-react';

interface DebugConsoleProps {
  logs: DebugLog[];
}

export const DebugConsole: React.FC<DebugConsoleProps> = ({ logs }) => {
  return (
    <div className="bg-gray-900 text-gray-100 p-4 rounded-lg h-64 overflow-y-auto">
      <div className="flex items-center gap-2 mb-2">
        <Terminal size={18} />
        <h2 className="text-sm font-mono">Debug Console</h2>
      </div>
      <div className="space-y-2">
        {logs.map((log, index) => (
          <div
            key={index}
            className={`font-mono text-sm ${
              log.type === 'error' ? 'text-red-400' :
              log.type === 'success' ? 'text-green-400' :
              'text-blue-400'
            }`}
          >
            <span className="text-gray-500">[{new Date(log.timestamp).toLocaleTimeString()}]</span>{' '}
            {log.message}
            {log.details && (
              <pre className="mt-1 text-xs text-gray-400 overflow-x-auto">
                {JSON.stringify(log.details, null, 2)}
              </pre>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};